#root{
    grid-template-columns: 20% 80%;
    display: grid;
    --prim-color:#8102e9;
    --card-color: #cae1ff;
    color:#fff;
    margin: -8px;
}

html,
body{
  width:100%;
  overflow-x:hidden;
}

button{
    background: rgb(255, 0, 221);
    border-radius: 24px;
    padding: 5px 20px;
    color: white;
    font: normal bold 18px "Open San", sans-serif;
    text-align: center;
    margin-bottom: 10px;
    border:0;
    cursor: pointer;
    width: 150px;
    height: 42px;
}
button:hover{
    transition: all 0.3s;
    background: rgb(89, 0, 253);
}



button {
    width: 151px;
    height: 50px;
    border: none; /*This gives it the rounded corners*/
    color: #fff; /*This is for the text color on buttons*/
    display: inline-block; /*Anchor tags are inline elements by default. When you have a anchor tag there can be problems with the padding so display will be set to inline-block*/
    /*I copied the font-weight and size from fig*/
    font-weight: bold;
    border-radius: 10px;
    font-size: 18px;
    text-decoration: none;
    position: relative; /*Add this so things will stay inside of the button*/ 
    z-index: 95; /*Made glowing effect appear on all buttons*/
    cursor: pointer;
    -webkit-text-stroke-width: 0;
    
  }
  
  button:before {/*2hrs 30mins fixure out how to continously animate. had to remove hover duh*/
    content:'';/*Inserts the before into html. Makes it exist there*/
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
  }
  
  button:active {
    color: red;
  }
  
  button:active:after {
    background: rgb(89, 0, 253);
  }
  
  button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    
    left: 0;
    top: 0;
    border-radius: 10px;
  }
  
  @keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
  }





.page-container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    grid-column: 2;
    margin-top: 5%;
    margin-bottom: 8%;
    
}

