.view-section{
    min-width: 40%;
    text-align: center;
    background-color: #f2f2f2;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
}

.view-section h2{
    margin: 0 0 15px 0;
    border-bottom: 1px groove black;
}