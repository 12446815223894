.bug-view{
    position: absolute;
    z-index: 10;
    width: 60vw;
    border: 1px solid black;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.705);
    border-radius: 5px;
    background-color: var(--prim-color);
    margin: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: black;
}
.bug-view h1{
    min-width: 100%;
    text-align: center;
    color: white;
    font-size: 3rem;
    padding-bottom: 20px;
    border-bottom: 1px groove white;
}
.close-btn{
    position: absolute;
    margin: 10px;
    right:0;
    top: 0;
}


