.sidebar{
    position: fixed;
    grid-column: 1;
    width: 20%;
    height: 100vh;
    background: linear-gradient(0deg,#de00f2 0%, #3300ff 100%);
    text-align: center;
}
.sidebar li{
    padding: 10px;
    font-size: 1.5rem;
    border-bottom: 1px groove #ffebd3;
}
.sidebar li:hover{
    transition: all 0.3s;
    background-color: #0dfa04;
}
.sidebar ul{
    list-style: none;
    padding: 0;
}
.sidebar .nav-link{
    text-decoration: none;
    color: white;
}
.sidebar .logout{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.sidebar .brand{
    font-size: 2.2rem;
}
