.bug-card{
    background: linear-gradient(0deg,#de00f296 0%, #3300ff96 100%);
    box-shadow: 2px 2px 2px 2px #000000a8;
    text-align: center;
    margin: 10px;
    min-width: 275px;
    min-height: 200px;
    border-radius: 36px;
}
.bug-card:hover{
    transition: all 0.3s;
    transform: scale(1.05);
}
.bug-card h2{
    font-size: 37px;
    margin: 18px;
    -webkit-text-stroke: black;
    -webkit-text-stroke-width: 1px;

}

.bug-card h4{
    font-size: 24px;
    margin: 21px;
    -webkit-text-stroke: black;
    -webkit-text-stroke-width: 1px;
}

.bug-card h5{
    font-size: 22px;
    margin: 0px;
    -webkit-text-stroke: black;
    -webkit-text-stroke-width: 1px;
}