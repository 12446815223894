.bug-create{
    width: 60vw;
    background: linear-gradient(0deg,#de00f2b0 0%, #3300ffbf 100%);
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    border-radius: 49px;
    position: absolute;
    z-index: 10;
    padding: 1% 5%;
    box-shadow: 2px 2px 2px 2px #000000a8;
}

.bug-create h1{
    min-width: 100%;
    text-align: center;
    font-size: 2.5rem;
    border-bottom: 1px groove white;
}

.bug-create form{
    min-width: 80%;
    display: grid;
    grid-template-columns: 30% 70%;
    column-gap: 20px;
    row-gap: 20px;
}

.bug-create label{
    text-align: right;
    font-size: 1.5rem;
}

.bug-create input,textarea,select{
    border-radius: 5px;
    border: none;
}

.bug-create button{
    grid-column: 2;
}

