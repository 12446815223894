.loginBG{
    width:100vw;
    height: 100vh;
    background: url('../../../images/imageBG.jpg');
    background-size: cover;
    grid-column: 1/2;
    background-color:#de00f2;
    background-blend-mode: multiply;
    background-attachment: fixed;
    position: absolute;
}
.login-panel{
    background: linear-gradient(0deg,#de00f27a 0%, #3300ff 100%);
    width: 386px;
    border-radius: 25px;
    margin:25vh auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding: 44px 63px;
}
.login-panel h1{
    width: 100%;
    font-size: 50px;
}
.login-panel input{
    width: 70%;
    height: 30px;
    border-radius: 5px;
    border: none;
    margin: 10px;
    padding-left: 5px;
}